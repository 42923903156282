<template>
    <b-modal
        v-model="modalShow"
        ref="alertModal"
        hide-header
        hide-footer
        centered
        @hidden="hide"
    >
        <div class="text-center">
            <font-awesome-icon :icon="['fas', 'times-circle']" class="error-icon"/>
            <p class="pt-3 ft-16">{{text}}</p>
        </div>
        <div class="text-right">
            <button class="btn-confirm" @click="hide">OK</button>
        </div>
    </b-modal>
</template>

<script>
export default {
    props:{
        showModal:{
            required:true,
            type:Boolean
        },
        text:{
            required:true,
            type:String
        }
    },
    data(){
        return{
            modalShow : this.showModal
        }
    },
    methods:{
        show(){
            this.modalShow = true;
        },
        hide(){
            this.modalShow = false;
            this.$emit('hideModal',true);
        }
    }
}
</script>

<style lang="scss" scoped>
.error-icon{
    font-size: 100px;
    color: var(--primary-color);
}
.ft-16{
    font-size: 18px;
}
.btn-confirm{
    border: none;
    color: #fff;
    font-size: 18px;
    background-color: #7cd1f9;
    padding: 10px 15px;
    border-radius: 5px;
}
</style>