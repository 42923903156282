<template>
  <div>
    <b-row class="no-gutters">
      <b-col md="6" class="justify-content-start">
        <b-input-group>
          <b-form-input
            id="header-search-bar"
            class="search-bar"
            @keyup.enter="handleSearch"
            placeholder="Search Product Name, SKU"
            v-model="search"
          >
          </b-form-input>
          <b-input-group-append is-text class="mr-2">
            <b-iconstack font-scale="2" type="submit" @click="handleSearch">
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col md="6" class="mt-2 text-sm-right">
        <b-form-checkbox
          size="md"
          v-model="showOnly"
          :value="1"
          :checked="1"
          :unchecked-value="0"
          @input="setshowOnly($event)"
          >Display Only Selected</b-form-checkbox
        >
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="ProductList"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(isCheck)="data">
            <div>
              <b-form-checkbox-group v-model="selected">
                <b-form-checkbox
                  :key="data.item.id"
                  :value="data.item.id"
                  @change="checkActive(data.item.id, $event)"
                  :disabled="
                    id !== '0'
                      ? isRedeem == 1
                        ? data.item.isCheck == true
                          ? true
                          : false
                        : false
                      : false
                  "
                >
                </b-form-checkbox>
              </b-form-checkbox-group>
            </div>
          </template>
          <template v-slot:cell(article_no)="data">
            <div>
              <span
                >SKU-{{ data.item.article_no }}-{{ data.item.barcode }}</span
              >
            </div>
          </template>
          <template v-slot:cell(price)="data">
            <div class="text-center">
              <div>
                {{
                  data.item.price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                }}
              </div>
            </div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    ProductList: {
      required: true,
      type: Array,
    },
    idList: {
      required: false,
      type: Array,
    },
    isBusy: {
      required: false,
      type: Boolean,
    },
    fields: {
      required: true,
      type: Array,
    },
    isRedeem: {
      required: false,
      type: Number,
    },
    id: {
      required: true,
      type: String,
    },
    productSelectedShowonly: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      productSelect: this.idList,
      showOnly: this.productSelectedShowonly,
      sellChanelList: [],
      refreshInput: true,
      selected: [],
      productDelete: [],
      defaultProductDelete: [],

      search: "",
    };
  },
  async created() {
    if (this.id !== "0") {
      this.showOnly = true;
    } else {
      this.checkedSelect();
      this.showOnly = this.productSelectedShowonly;
    }
  },
  methods: {
    setshowOnly(val) {
      if (val) {
        this.$emit("displayOnly", 1, 1);
      } else {
        this.$emit("displayOnly", 0, 1);
      }
    },
    handleSearch() {
      this.$emit("searchProduct", this.search);
    },
    checkActive(id, val) {
      this.productSelect = Array.from(new Set(this.productSelect));
      if (this.productSelect.includes(id)) {
        let deleteProduct = this.productSelect.splice(
          this.productSelect.indexOf(id),
          1
        );
        this.productDelete = [...deleteProduct, ...this.productDelete];
      } else {
        this.productSelect = this.selected;
      }
      this.$emit("select", this.productSelect);
      this.$emit("delete", this.productDelete);
    },
    checkedSelect() {
      for (const index in this.ProductList) {
        if (this.ProductList[index].isCheck) {
          this.selected.push(this.ProductList[index].id);
          this.selected = Array.from(new Set(this.selected));
        }
      }
    },
  },
  async mounted() {
    if (this.ProductList?.length > 0) {
      await this.checkedSelect();
    }
  },
  watch: {
    ProductList: function () {
      if (this.ProductList?.length > 0) {
        this.productSelect = this.idList;
        this.checkedSelect();
      }
    },
    productSelectedShowonly() {
      this.showOnly = this.productSelectedShowonly;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--theme-secondary-color);
  color: var(--font-color);
}
::v-deep .custom-checkbox.b-custom-control-lg > label,
.input-group-lg .custom-checkbox > label {
  font-size: 16px;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
input#header-search-bar::placeholder {
  text-transform: capitalize;
}
</style>
